import React, {useRef, useState } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from "react-router-dom"

export default function Login() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e){
    e.preventDefault()


    try {
        setError('')
        setLoading(true)
         await login(emailRef.current.value, passwordRef.current.value)
         navigate("/")

    } catch {
      setError('Failed to log in')
    }
    setLoading(false)
  }

  return (
      <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                  <div className="w-100" style={{maxWidth: "400px"}}> 

      <Card>
        <Card.Body>
        <h2 className="text-center mb-4">Logg inn</h2>

        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>

        <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required/>
        </Form.Group>
        
        <Form.Group id="password">
            <Form.Label>Passord</Form.Label>
            <Form.Control type="password" ref={passwordRef} required/>
        </Form.Group>
          <Button type="submit" className="w-100 my-1" disabled={loading} >Logg inn</Button>
        
        </Form>
            <div className="w-100 text-center mt-3">
                <Link to="/forgot-password">Glemt passord?</Link>
            </div>


        </Card.Body>
      </Card>
    <div className="w-100 text-center mt-2">Har du ikke en konto? <Link to="/signup">Registrer bruker</Link> </div>
    </div>
    </div>

  )
}
