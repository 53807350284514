import React, {useState, useEffect} from 'react'
import {db} from "../firebase"
import styled from 'styled-components'
import {collection, getDocs, limit, query, orderBy} from 'firebase/firestore';

import { FaRegClock, FaPencilAlt } from "react-icons/fa";



export default function Projects() {

  const [posts, setPosts] = useState([])



  useEffect(()=>{
    const postCollectionRefQuery = query(collection(db, "projects"),orderBy("createdAt",'desc'), limit(20))

    const getAllPosts = async ()=>{
    const data = await getDocs(postCollectionRefQuery);
      setPosts(data.docs.map((doc)=>({...doc.data(), id: doc.id})))
    }
    getAllPosts()

  },[])

  const formatLink = (link) => {
    // Check if link is defined and a string
    if (typeof link === 'string') {
      // Check if the link already starts with http:// or https://
      if (link.startsWith('http://') || link.startsWith('https://')) {
        return link; // Return the link as is if it already contains the protocol
      }
  
      // If the link doesn't contain the protocol, prefix it with http://
      return `http://${link}`;
    }
  
    // Return a default value or handle the undefined case as appropriate
    return '#'; // Using '#' as a fallback, but you might choose a different approach
  };

  return (
    <StyledDiv className="container">
  <div className="row justify-content-center">
  <div className="col-lg-10">


    <h2>Gamle prosjekter og nettsider.</h2>
    <p>Under er en oversikt over gamle prosjekter av alle slag</p>
</div>
</div>
  <div className="row justify-content-center">

    {posts.map((post)=>{
      return(  
    <div key={post.id} className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-5">
      <div className="card my-3">
        <img className="card-img" src={post.image} alt="postImage"/>
        <div className="card-img-overlay">
          <span className="btn btn-light btn-sm">#{post.theme}</span>
        </div>
        <div className="card-body">
          <h4 className="card-title">{post.title}</h4>
          <small className="text-muted cat">
          <FaPencilAlt color="#12192c" fontSize="16px" className="icons"/> {post.writer}
          </small>
          <p className="card-text">{post.short}</p>
          <p className="card-text">{post.long}</p>

          <p><a href={formatLink(post.link)} target="_blank" rel="noopener noreferrer">{post.link}</a></p>
        </div>
        <div className="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
          <div className="views"><FaRegClock color="#12192c" fontSize="16px" className="icons me-2"/>{new Date(post.createdAt).toString().substr(4, 11)}
          </div>

           
        </div>
      </div>
    </div>
 
      )
    })}  
    </div>

    
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
margin-top:60px;

position: relative;
z-index:2;

body {
  padding: 2rem 0rem;
}
.card-img-overlay{
  height:50px;
}
.card{
  border:1px solid #969ba3;
  padding:10px;
  z-index:2;
}
.card:hover{
  border:1px solid gray;
}
.card-img {
  border-radius:10px;
  max-height:40vh;
  max-width:auto;
  object-fit:  cover;
}

.card-title {
  margin-bottom: 0.3rem;
}

.cat {
  display: inline-block;
  margin-bottom: 1rem;
}

.fa-users {
  margin-left: 1rem;
}

.card-footer {
  font-size: 0.8rem;
}
.btn-light{
  border: 1px solid gray;
}

h2,h3,p{
    font-family: 'Raleway', sans-serif;

}

`
