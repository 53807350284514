import React, {useRef, useState} from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from "react-router-dom"


export default function Signup() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


  async function handleSubmit(e){
    e.preventDefault()

    if(passwordRef.current.value !== passwordConfirmRef.current.value){
      return setError('Passord må være like')
    }
    try {
        setError('')
        setLoading(true)
         await signup(emailRef.current.value, passwordRef.current.value)
         navigate("/")

    } catch {
      setError('Failed to create account')
    }
    setLoading(false)
  }

  return (
      <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                  <div className="w-100" style={{maxWidth: "400px"}}> 

      <Card>
        <Card.Body>
        <h2 className="text-center mb-4">Registrer bruker</h2>


        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>

        <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required/>
        </Form.Group>
        
        <Form.Group id="password">
            <Form.Label>Passord</Form.Label>
            <Form.Control type="password" ref={passwordRef} required/>
        </Form.Group>
        
        <Form.Group id="passwordConfirm">
            <Form.Label>Bekreft passord</Form.Label>
            <Form.Control type="password" ref={passwordConfirmRef} required/>
        </Form.Group>
        
          <Button type="submit" className="w-100 my-1" disabled={loading} >Registrer</Button>
        
        </Form>

        </Card.Body>
      </Card>
    <div className="w-100 text-center mt2">Har du alerede en konto? <Link to="/login">Logg inn</Link></div>
    </div>
    </div>

  )
}
