import React, {useRef, useState} from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom"


export default function UpdateProfile() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updateEmail, updatePassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


   function handleSubmit(e){
    e.preventDefault()

    if(passwordRef.current.value !== passwordConfirmRef.current.value){
      return setError('Passord må være like')
    }

    const promises = []
    setLoading(true)
    setError('')

        if(emailRef.current.value !== currentUser.email){
            promises.push(updateEmail(emailRef.current.value))
        }
        if(passwordRef.current.value){
            promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises).then(()=>{
            navigate('/')
        }).catch(()=>{
            setError('Failed to update')
        }).finally(()=>{
            setLoading(false)
        })
  }

  return (
      <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                  <div className="w-100" style={{maxWidth: "400px"}}> 

      <Card>
        <Card.Body>
        <h2 className="text-center mb-4">Oppdater profil</h2>


        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>

        <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email}/>
        </Form.Group>
        
        <Form.Group id="password">
            <Form.Label>Passord</Form.Label>
            <Form.Control type="password" ref={passwordRef}  placeholder="la stå blank for å beholde gammelt passord"/>
        </Form.Group>
        
        <Form.Group id="passwordConfirm">
            <Form.Label>Bekreft passord</Form.Label>
            <Form.Control type="password" ref={passwordConfirmRef}  placeholder="la stå blank for å beholde gammelt passord"/>
        </Form.Group>
        
          <Button type="submit" className="w-100 my-1" disabled={loading} >Oppdater</Button>
        
        </Form>

        </Card.Body>
      </Card>
    </div>
    </div>

  )
}
