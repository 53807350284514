import React, {useRef, useState } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link } from "react-router-dom"

export default function ForgotPassword() {

  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")


  async function handleSubmit(e){
    e.preventDefault()


    try {
        setMessage('')
        setError('')
        setLoading(true)
         await resetPassword(emailRef.current.value)
        setMessage('Sjekk inbox for instruksjoner')
    } catch {
      setError('Failed to reset password')
    }
    setLoading(false)
  }

  return (
      <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                  <div className="w-100" style={{maxWidth: "400px"}}> 

      
      <Card>
        <Card.Body>
        <h2 className="text-center mb-4">Reset passord</h2>

        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}

        <Form onSubmit={handleSubmit}>

        <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required/>
        </Form.Group>
        
          <Button type="submit" className="w-100 my-1" disabled={loading} >Reset Passord</Button>
        
        </Form>
            <div className="w-100 text-center mt-3">
                <Link to="/login">Logg inn</Link>
            </div>


        </Card.Body>
      </Card>
    <div className="w-100 text-center mt-2">Har du ikke en konto? <Link to="/signup">Registrer bruker</Link> </div>
    </div>
    </div>
  )
}
