import React, {useState, useEffect} from 'react'
import {db} from "../firebase"
import styled from 'styled-components'
import {collection, getDocs, limit, query, orderBy} from 'firebase/firestore';
import {Link} from "react-router-dom"
import background from "../madia/background2.jpg"
import { FaRegClock, FaRegComments, FaPencilAlt } from "react-icons/fa";



export default function Home() {

  const [posts, setPosts] = useState([])
  const [comments, setComments] = useState([])

  const [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.scrollY)
  useEffect(()=>{
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll',handleScroll)
  },[])


  const formatLink = (link) => {
    // Check if link is defined and a string
    if (typeof link === 'string') {
      // Check if the link already starts with http:// or https://
      if (link.startsWith('http://') || link.startsWith('https://')) {
        return link; // Return the link as is if it already contains the protocol
      }
  
      // If the link doesn't contain the protocol, prefix it with http://
      return `http://${link}`;
    }
  
    // Return a default value or handle the undefined case as appropriate
    return '#'; // Using '#' as a fallback, but you might choose a different approach
  };


  useEffect(()=>{
    const postCollectionRefQuery = query(collection(db, "posts"),orderBy("createdAt",'desc'), limit(20))

    const getAllPosts = async ()=>{
    const data = await getDocs(postCollectionRefQuery);
      setPosts(data.docs.map((doc)=>({...doc.data(), id: doc.id})))
    }
    getAllPosts()

    const commentsRef = query(collection(db, "comments"),orderBy("createdAt",'desc'))
    const getComments = async () =>{
      const myComments = await getDocs(commentsRef)
      //const myComments = myComments.docs.map((doc)=>({...doc.data(), id: doc.id}))
      setComments(myComments.docs.map((doc)=>({...doc.data(), id: doc.id})))

      
  }
  getComments()
  },[])

       function commentsWithId(id){
         let arr = []
         for(let i = 0; i < comments.length; i++){
           if(comments[i].postId === id){
             arr.push(comments[i])
           }
         }        
         return (arr.length)
      }

  return (
    
    <StyledDiv>
    <div className='overlay'></div>
    <img src={background} className="backgroundImage" style={{transform: `translateY(${-offsetY * 0.3}px)`}} alt="banana"/>

    <div className="container">
  <div className="row justify-content-center">
  <div className="col-lg-10">


    <h2>Hei og velkommen,</h2>
    <h3>trivelig at du stakk innom.</h3>
     <p>Mitt navn er <Link to="/about"><strong>Alexander.</strong></Link> Dette er min private nettside hvor jeg lagrer diverse private prosjekter jeg jobber med. (ツ)</p>
</div>
</div>
    {posts.map((post)=>{
      return(  
  <div key={post.id} className="row justify-content-center">
    <div className="col-12 col-sm-8 col-md-8 col-lg-8">
      <div className="card my-3">
        <img className="card-img" src={post.image} alt="postImage"/>
        <div className="card-img-overlay">
          <span className="btn btn-light btn-sm">#{post.theme}</span>
        </div>
        <div className="card-body">
          <h4 className="card-title">{post.title}</h4>
          
          <small className="text-muted cat">
          <FaPencilAlt color="#12192c" fontSize="16px" className="icons"/> {post.writer}
          </small>
          <p className="card-text">{post.short}</p>
<p><a href={formatLink(post.link)} target="_blank" rel="noopener noreferrer">{post.link}</a></p>
          <Link to={`/post/${post.id}`}>  <button className="btn btn-info">Se mer</button></Link>
        </div>
        <div className="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
          <div className="views"><FaRegClock color="#12192c" fontSize="16px" className="icons me-2"/>{new Date(post.createdAt).toString().substr(4, 11)}
          </div>
          <div className="stats">
          <FaRegComments  color="#12192c" fontSize="18px" className="icons"/> {commentsWithId(post.id)}
          </div>
           
        </div>
      </div>
    </div>
    </div> 
  
 
      )
    })}
     </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`


  position: relative;
z-index:2;

.container{
  margin-top:60px;
position: relative;
z-index:3;
}


body {
  padding: 2rem 0rem;
}
.card-img-overlay{
  height:50px;
}
.card{
  border:1px solid #969ba3;
  padding:10px;
  z-index:2;
}
.card:hover{
  border:1px solid gray;
}
.card-img {
  border-radius:10px;
  max-height:40vh;
  max-width:auto;
  object-fit:  cover;
}

.card-title {
  margin-bottom: 0.3rem;
}

.cat {
  display: inline-block;
  margin-bottom: 1rem;
}

.fa-users {
  margin-left: 1rem;
}

.card-footer {
  font-size: 0.8rem;
}
.btn-light{
  border: 1px solid gray;
}

h2,h3,p{
    font-family: 'Raleway', sans-serif;
    color:white;
}

.card-text{
  font-family: 'Raleway', sans-serif;
    color:black;
}

.overlay{
  margin-top:-60px;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:105%;
  z-index: 2;
  background-color: rgba(0,0,0,.3);
}

.backgroundImage{
  margin-top:-60px;
  position:fixed;
  object-fit:cover;
}

`
