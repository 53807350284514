import React from 'react'
import styled from 'styled-components'


export default function ArrowCircle() {

  
  return (
    <Circle>

    <div className="zero">
        <div id="a" className="one">
           <span id="b" className="two"></span>
           <span id="c" className="three"></span>
        </div>
    </div>

    </Circle>
  )
}


const Circle = styled.div`


.zero{
    position: relative;
}

.one{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border:3px solid #202124;
  border-color:#ededed transparent #ededed transparent;
  display: inline-block;

}

.two{
	transform:rotate(100deg);
	position: absolute;
	left: 15px;
	top: -4px;
    border: 3px solid #ededed;
    border-color:#ededed #ededed transparent  transparent;
    width:13px;
    height:13px;
}

.three{
	transform:rotate(-100deg);
	position: absolute;
	left: 5px;
	bottom: 1px;
    border: 3px solid #ededed;
    border-color:#ededed #ededed transparent  transparent;
    width:15px;
    height:15px;
}

.one:hover{
    transform:rotate(100deg);
    transition: ease-in-out .2s;
}

#a:hover  #c{
    left: 3px;
    bottom: -8px;
    transition: ease-in-out .2s;

}

`
