import React from "react";
import Signup from "./components/Signup"
import { AuthProvider} from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import OwnerRoute from "./components/OwnerRoute";
import ForgotPassword from "./components/ForgotPassword"
import UpdateProfile from "./components/UpdateProfile";
import Home from "./components/Home";
import Navigation from "./components/Navigation"
import CreatePost from "./components/CreatePost";
import SinglePost from "./components/SinglePost";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import CreateProject from "./components/CreateProject";



function App() {
  return (
    <>

   

    <Router>
       <AuthProvider>
       <Navigation/>
       <Routes>
       <Route path="/" element={<Home/>}/>
       <Route path="/post/:id" element={<SinglePost/>} />
       <Route path="/createpost" element={<OwnerRoute> <CreatePost/> </OwnerRoute>}/>
       <Route path="/createproject" element={<OwnerRoute> <CreateProject/> </OwnerRoute>}/>
       <Route path="/about" element={<About/>}/>
       <Route path="/projects" element={<Projects/>}/>
       <Route path="/contact" element={<Contact/>}/>

        {/* Routes for user auth */}
    
        <Route path="/user-profile" element={<PrivateRoute> <Dashboard /> </PrivateRoute>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/update-profile" element={<PrivateRoute> <UpdateProfile/> </PrivateRoute> }/>
        </Routes>
       </AuthProvider>
    </Router>
 
</>
  );
}

export default App;
