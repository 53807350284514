import React from 'react'
import {useEffect, useState, useRef} from 'react';
import {db, storage} from '../firebase';
import styled from 'styled-components';
import { collection, addDoc} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import { useAuth } from '../contexts/AuthContext'




export default function CreatePost() {

  const formRef = useRef();

  const title = useRef()
  const short = useRef()
  const long = useRef()
  const theme = useRef()
  const link = useRef()


    //const [title,setTitle] = useState("");
    //const [short,setShort] = useState("");
    //const [long,setLong] = useState("");
    //const [theme,setTheme] = useState("");
    const [imageUrl, setImageUrl] = useState(null)
    const [imageUrl2, setImageUrl2] = useState(null)
    const [user, setUser] = useState("");
    const [progress, setProgress] = useState(0)


    const { currentUser } = useAuth()
    useEffect(()=>{

             if(currentUser){
             const email = currentUser.email
             const user = email.slice(0, email.indexOf('@')); 
             setUser(user)
        } 
       },[currentUser])
      
    const postCollectionRef = collection(db,"posts")

    const createPost = async (e) =>{
      e.preventDefault();

        await addDoc(postCollectionRef, {title:title.current.value, short:short.current.value, long:long.current.value,theme:theme.current.value, link:link.current.value, image:imageUrl, image2:imageUrl2, writer:user,  createdAt: Date.now()})
        formRef.current.reset();

      }


      const handleImageUpload = (e) =>{
        e.preventDefault();
        const file = e.target[0].files[0];
        uploadImage(file)
      }
      const handleImageUploadImg2 = (e) =>{
        e.preventDefault();
        const file = e.target[0].files[0];
        uploadImage2(file)
      }
      const uploadImage = (file) => {
        if(!file) return;
        const imageFileName = `${Math.round(Math.random() * 1000000000000).toString()}`;
        const storageRef = ref(storage, `/files/${imageFileName}`);           // firebase storage from config | location and filename
        const uploadTask = uploadBytesResumable(storageRef, file)    //  uploading to firestore
    
        uploadTask.on("state_changed",                              // some shit about progress, not important
        (snapshot)=>{
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes)*100);
          setProgress(prog);
        }, 
        (error)=> console.log(error), 
        ()=>{
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {              // getting the firestore url for the image
            console.log("File available at", downloadURL);
            setImageUrl(downloadURL)
        })
      })
             
    }

    const uploadImage2 = (file) => {
      if(!file) return;
      const imageFileName = `${Math.round(Math.random() * 1000000000000).toString()}`;
      const storageRef = ref(storage, `/files/${imageFileName}`);           // firebase storage from config | location and filename
      const uploadTask = uploadBytesResumable(storageRef, file)    //  uploading to firestore
  
      uploadTask.on("state_changed",                              // some shit about progress, not important
      (snapshot)=>{
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes)*100);
        setProgress(prog);
      }, 
      (error)=> console.log(error), 
      ()=>{
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {              // getting the firestore url for the image
          console.log("File available at", downloadURL);
          setImageUrl2(downloadURL)
      })
    })
           
  }

  return (
    <StyledDiv className="container">
    <div className="contentWrapper">
    <h3>Add new post</h3>
    <form onSubmit={createPost} ref={formRef} className="w-100">
    <input className="iput" placeholder="Title" type="text" ref={title}/>
    <input className="iput" placeholder="short" type="text" ref={short}/>
    <input className="iput" placeholder="theme" type="text" ref={theme}/>
    <input className="iput" placeholder="link" type="text" ref={link}/>
    <textarea className="iput" placeholder="long" type="textarea" ref={long}/>

    </form>
<div className="my-5">
<h5>Legg til bilde</h5>
    <form onSubmit={handleImageUpload}>
    <h3>{progress} %</h3>
    <input type="file"/>
    <button type="submit">Upload</button>
    </form>

    <img src={imageUrl} className="thumbnailimage" alt="postimage"/>
</div>

<div className="my-5">
<h5>Legg til bilde 2</h5>
    <form onSubmit={handleImageUploadImg2}>
    <h3>{progress} %</h3>
    <input type="file"/>
    <button type="submit">Upload</button>
    </form>

    <img src={imageUrl2} className="thumbnailimage" alt="postimage"/>
</div>


<button onClick={createPost} className="btn btn-success m-5">legg til post</button>


</div>
    </StyledDiv>
  )
}


const StyledDiv = styled.div`
margin-top:60px;
display: flex;
justify-content: center;


.contentWrapper{
  max-width:600px;
  display: flex;
  flex-direction: column;
  text-align: start;
  flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 50%;
}


.thumbnailimage{
  width:30%;
  height:auto;
}

.iput{
  margin:10px;
  width:100%;

}

`
