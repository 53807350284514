import React from 'react'
import styled from 'styled-components'

export default function Hamburger(props) {
  return (
<StyledDiv open={props.open}>
    <span className="a" />
    <span className="d"/>
    <span className="b"/>
    <span className="d"/>
    <span className="c"/>  
    </StyledDiv>
  )
}
const StyledDiv = styled.div`
z-index:1000;
position:relative;

.a{
    border:2px solid #ededed;
    border-radius: 5px;
    display: block;
    transition: all 0.7s ease;
    transform: ${ props=>props.open ?   (`rotate(0deg) translateX(70px)`) : (`rotate(45deg) translateY(11px)`)};
    border-color: ${ props=>props.open ? (`#12192c`) : (`#ededed`)};
}
.b{
    border:2px solid #ededed;
    border-radius: 5px;
    display: block;
    transition: all 0.7s ease;
    transform: ${ props=>props.open ?   (`translateX(70px)`) : (`rotate(360deg) translateY(-100px)`)};
    border-color: ${ props=>props.open ? (`#12192c`) : (`#ededed`)};
}
.c{
    border:2px solid #ededed;
    border-radius: 5px;
    display: block;
    transition: all 0.7s ease;
    transform: ${ props=>props.open ? (`rotate(0deg) translateX(70px)`) : (`rotate(-45deg) translateY(-11px)`)};
    border-color: ${ props=>props.open ?  (`#12192c`) : (`#ededed`)};
}
.d{
    border:2px solid transparent;
    border-radius: 5px;
    display: block;
    transition: all 0.7s ease;
    transform: ${ props=>props.open ?   (`translateX(70px)`) : (`rotate(360deg) translateY(-100px)`)};
    border-color: ${ props=>props.open ?  (`transparent`) : (`transparent`)};
}


`
