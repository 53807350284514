import React from 'react'
import styled from "styled-components"
import alex from "../madia/alex2.png"

export default function Contact() {
  return (
    <StyledDiv className="container">
    <div className="row justify-content-start mb-4">
    <div className="col-sm-12">  
    <h1>Ta kontakt</h1>
    </div>
    </div>

    <div className="row justify-content-start">
        <div className="col-sm-6 col-md-4">  
    <img src={alex} alt="bioImage" className="image"></img>
    </div>

        <div className="col-sm-6 col-md-4">
    <p>Send meg en epost hvis du lurer på noe.</p>
    <p><strong>Alexander.utne@gmail.com</strong></p>

    </div>
    </div>
    </StyledDiv>
  )
}


const StyledDiv = styled.div`

margin-top:60px;

.image{
    width:100%;
    height: auto;
    
}
`