import React, {useState,useEffect, useRef} from 'react'
import {useParams} from "react-router-dom";
import { getDoc, doc  } from "firebase/firestore";
import {db} from "../firebase"
import styled from 'styled-components'
import { FaRegClock, FaRegComments, FaPencilAlt,FaArrowAltCircleLeft, FaPaperPlane, FaTrash, FaRegEdit } from "react-icons/fa";
import {Link} from "react-router-dom"
import { useAuth } from '../contexts/AuthContext'
import { collection, addDoc, getDocs, limit, query, orderBy, where,deleteDoc, updateDoc} from 'firebase/firestore';


export default function SinglePost() {

    const { currentUser } = useAuth()
    const [post, setPost] = useState()
    const [loading, setLoading] = useState(true)
    const [loadingComments, setLoadingComments] = useState(true)
    const {id} = useParams();
    const [comments, setComments] = useState([])
    const [editComments, setEditComments] = useState(false)


    const [test, setTest] = useState(1)

    const comment = useRef("")
    const newComment = useRef("")
    const formRef = useRef();


    const handleEditComments = function(){
      setEditComments(!editComments)
  }

    const createComment = async (e) =>{
        e.preventDefault();
            const commentCollectionRef = collection(db,"comments")

        if(!currentUser){
            return alert("du må logge inn for å kommentere");
        }else
        {
            const email = currentUser.email
            const user = email.slice(0, email.indexOf('@')); 
            await addDoc(commentCollectionRef, {comment:comment.current.value, writer:user, postId:id, createdAt: Date.now()})
            formRef.current.reset();
            setTest(test+1)
       } 
      }

      const deleteComment = async (id)=>{
        const commentDoc = doc(db, "comments",id);
        await deleteDoc(commentDoc)
        setTest(test+1)
      }

      const editComment = async (id) =>{

        const editDoc = doc(db, "comments", id);
        await updateDoc(editDoc, {comment:newComment.current.value, createdAt: Date.now()})
        setEditComments(false)
      }




    useEffect(()=>{
      const docRef = doc(db, "posts", id)
      let isCancelled = false;
        const getPost = async ()=>{
          const myDoc = await getDoc(docRef)
          if(!isCancelled){
                 if(myDoc.exists()){
                setPost(myDoc.data())
                setLoading(false)
            } else{
                console.log("some issue, post not loading")
            }
        }
          }
        getPost()
        return ()=>{
          isCancelled = true;
        }

      },[test,id])


      useEffect(()=>{
        const commentsRef = query(collection(db, "comments"),orderBy("createdAt",'desc'), where("postId", "==", id),limit(40))

        let isCancelled = false;
        const getComments = async () =>{
            const myComments = await getDocs(commentsRef)
            if(!isCancelled){
              setComments(myComments.docs.map((doc)=>({...doc.data(), id: doc.id})))
              setLoadingComments(false)
            }
            
        }
        getComments()
        return ()=>{
          isCancelled = true;
        }
      },[test, id])


  return (
    
    <StyledDiv className="container">
    {loading ? <p>Loading...</p> : (
        <>
  <div key={post.id} className="row justify-content-center">
    <div className="col-12 col-sm-10 col-md-10 col-lg-8">
      
      <div className="card my-3">
      <Link to={`/`}><FaArrowAltCircleLeft color="#12192c" fontSize="18px" className="icons my-2"/><span className="backarrow">Tilbake</span></Link>

          <h4 className="card-title">{post.title}</h4>
          <small className="text-muted cat">
          <FaPencilAlt color="#12192c" fontSize="16px" className="icons"/> {post.writer}
          </small>
          <p className="card-text">{post.short}</p>
          <p><a href={post.link} target="_blank" rel="noopener noreferrer">{post.link}</a></p>

        <img className="card-img" src={post.image} alt="postImage"/>
        <div className="card-body">
        <p className="card-text">{post.long}</p>
        </div>
        {post.image2 ? <img className="card-img" src={post.image2} alt="postImage"/> : null }
        <div className="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
          <div className="views"><FaRegClock color="#12192c" fontSize="16px" className="icons me-2"/>{new Date(post.createdAt).toString().substr(4, 11)}
          </div>
          <div className="stats">
            <FaRegComments color="#12192c" fontSize="18px" className="icons"/> {comments.length}
          </div>
        </div>
        <span className="line"/>

        <div className="w-100 my-2 mx-3">
        <form onSubmit={createComment} ref={formRef}>
        <textarea className="commentField" placeholder="Skriv en kommentar..." type="textarea" col="10" ref={comment} />
        <FaPaperPlane color="#12192c" fontSize="25px" className="sendIcon mx-3" onClick={createComment}/>
        </form>
        <div>

        {
            loadingComments ? (<p>Loading...</p>) : (comments.map((comment)=>{
                 return (
                    <div key={comment.id} className="commentWrapper">
                    <div className="nameAndDateAndButton">
                    <div><span><strong>{comment.writer}</strong></span>  <span><small>{new Date(comment.createdAt).toString().substr(4, 11)}</small></span></div> 
                    {currentUser && currentUser.email.slice(0, currentUser.email.indexOf('@')) === comment.writer ? (<span><FaTrash color="red" fontSize="13px" className="iconTrash me-2" onClick={()=>deleteComment(comment.id)}/><FaRegEdit color="black" fontSize="13px" className="iconTrash me-2" onClick={handleEditComments}/></span> ):(null)}
                    </div>
                    <p>{comment.comment}</p>

                    {editComments ?  <div className="edit">
                    <div className="editWrapper">

    <form onSubmit={()=>editComment(comment.id)} ref={formRef} className="formField">
        <textarea className="commentField2" placeholder="Rediger kommentar..." type="textarea" col="4" ref={newComment} />
        <button type="button" className="btn btn-outline-danger" onClick={()=>setEditComments(false)}>Lukk</button>
        <FaPaperPlane color="#12192c" fontSize="25px" className="sendIconEdit mx-3" onClick={()=>editComment(comment.id)}/>

        </form>
  </div>
      </div> : null}

                    </div>
                )
            }))
        }
        </div>
        </div>
      </div>
    </div>
    

   

  </div>
        </>
    )}
    
    </StyledDiv>
  )
}


const StyledDiv = styled.div`
margin-top:60px;

position: relative;
z-index:2;

.edit{
  z-index:80;
  position:fixed;
  width:100%;
  background-color: rgba(128,128,128,0.5);
  height:100%;
  left:0;
  top:0;
}


.commentWrapper{
  position: relative;
    background-color:white;
    border-radius:5px;
    width:90%;
    margin-bottom:5px;
  
}
.nameAndDateAndButton{
    display: flex;
    justify-content: space-between;
}

.card{
  border:none;
  padding:10px;
  z-index:2;
  background-color:#F0F2F5;
  border-radius:8px;

}

.backarrow{
    color:#12192c;
    text-decoration:none;
    margin-left:8px;
}

.line{
    border-top: 1px solid gray;

}

.commentField{
    width: 85%;
    min-height:100px;
    border: 1px solid gray;
    border-radius:5px;

}
.commentField2{
  z-index:120;
  width: 70%;
  min-height:100px;
  max-width:600px;

  background-color:white ;
}
.formField{
  z-index:120;
  padding:20px;
  width: 95%;
  min-height:200px;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color:white ;
}

.sendIcon{
    margin-bottom:30px;
    cursor: pointer;
}
.sendIconEdit{
  align-self: flex-end;
  cursor: pointer;
}
.btn-outline-danger{
  align-self: flex-start;
  margin-left: auto;
  cursor: pointer;
}
.editWrapper{
position: absolute;
top: 50%;
left:10vw;
width:90%;
max-width:900px;
height: 60%;
max-height:700px;
  
}

.iconTrash{
    cursor: pointer;
}
.card-text{
    white-space: pre-line;
    word-wrap: break-word; 


}

`