import React, {useState} from 'react'
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from "react-router-dom"

export default function Dashboard() {
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate()


    async function handleLogout(){
        setError('')
        try {
            await logout()
            navigate("/login")
        } catch {
            setError('Failed to log out')
        }
    }
  return (
    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
            <div className="w-100" style={{maxWidth: "400px"}}> 

    <Card>
        <Card.Body>
        <h2 className="text-center mb-4">Profil</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <strong>Email: </strong>{currentUser.email}
        <Link to="/update-profile" className="btn btn-primary w-100 mt-3">Oppdater profil</Link>

        </Card.Body>
    
    </Card>
    <div className="w-100 text-center mt2">
        <Button variant="link" onClick={handleLogout}>
            Logg ut
        </Button>
    </div>

    </div>
    </div>
  )
}
