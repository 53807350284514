import React from 'react'
import styled from "styled-components"
import alex from "../madia/alex.png"

export default function About() {
  return (
    <StyledDiv className="container">
    <div className="row justify-content-start ms-2">
    <div className="col-sm-12">  
    <h1>Heihei, jeg heter</h1>
    </div>
    </div>

    <div className="row justify-content-start">
        <div className="col-sm-8 col-md-6 parent">  
    <img src={alex} alt="bioImage" className="image"></img>
    <StyledText><h1 className="textImage" data-glitch="Alexander Utne">Alexander Utne</h1></StyledText>
    </div>

        <div className="col-sm-6 col-md-4">
		<p>Hei! Jeg er Alexander, en teknologi-entusiast fra Tromsø.  Nylig har jeg dykket dypt inn i en fascinerende verden av Firebase og React</p>
<p>Dette er min nettside hvor jeg lagrer mine digitale prosjekter.</p>
<p>Hvis du har spørsmål, eller bare vil si hei, send meg gjerne en e-post til <strong>Alexander.utne@gmail.com</strong>.</p>

    </div>
    </div>




    </StyledDiv>
  )
}

const StyledText = styled.div`
.textImage {
    z-index:10;
    font-family: 'Raleway', sans-serif;
    font-size:32px;
    font-weight:700;
	text-decoration: none;
	text-transform: uppercase;
	position: absolute;
    left:5%;
    top:1%;
	margin: 0;
	color: #fff;
	letter-spacing: 5px;
    @media  (max-width: 400px) {
font-size:20px;
}
	&:before, &:after {
		display: block;
		content: attr(data-glitch);
		text-transform: uppercase;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: .8;
	} &:after {
		color: #f0f;
		z-index: -2;
	} &:before {
		color: #0ff;
		z-index: -1;
	}
	&:hover {
		&:before {
			animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both 5
		}
		&:after {
			animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both 5
		}
	}
}
@media  (max-width: 400px) {
        font-size:20px;
}
@keyframes glitch {
	0% {
		transform: translate(0)
	}
	20% {
		transform: translate(-5px, 5px)
	}
	40% {
		transform: translate(-5px, -5px)
	}
	60% {
		transform: translate(5px, 5px)
	}
	80% {
		transform: translate(5px, -5px)
	}
	to {
		transform: translate(0)
	}
}


`

const StyledDiv = styled.div`

h1{
    font-family: 'Raleway', sans-serif;

}
p{
    font-family: 'Raleway', sans-serif;

}
margin-top:60px;

.image{
    width:100%;
    height: auto; 
}



   `

