import { useAuth } from '../contexts/AuthContext';
import { Navigate } from "react-router-dom";


export default function OwnerRoute({ children }) {
    const { currentUser } = useAuth();

  
    return currentUser && currentUser.email === "alexander.utne@gmail.com" ? children :  <Navigate to="/"/>  ;
  }
