import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ArrowCircle from '../madia/ArrowCircle'
import Hamburger from './Hamburger'
import { FaHome, FaInfoCircle, FaMailBulk, FaUserAlt,FaCogs } from "react-icons/fa";
import {Link} from "react-router-dom"
import { useAuth } from '../contexts/AuthContext';



export default function Navigation() {
       const { currentUser } = useAuth()

  useEffect(()=>{

   if(window.innerWidth < 950){
    setSides(["left", "right"])
    }   
        if(currentUser){
        const email = currentUser.email
        const user = email.slice(0, email.indexOf('@')); 
        setUser(user)
   }   
  },[currentUser])
    let index = 0
    const [sides, setSides] = useState(["left", "top", "right"])
    const [side, setSide] = useState(sides[index])
    const [open, setOpen] = useState(true)
    const [user, setUser] = useState()

    const handleSidebar = function(){
        setOpen(!open)
    }
    const handleChangeSide = function(){
     index = sides.indexOf(side) + 1
     if(sides[index] === undefined){
         setSide("left")
     } else{
            setSide(sides[index])
           }
    }



  return (
      <>

    <Sidebar open={open}>
   
    <div className={side} >
        <div className="arrowWrapper" onClick={handleChangeSide}> 
        <ArrowCircle/>
    </div> 
        <div className="xWrapper" onClick={handleSidebar}>
    <Hamburger open={open}/>
    </div>  

    <div className="elements">
        <Link onClick={()=>{setOpen(true)}} to="/"><div className="content"><FaHome color="#12192c" fontSize="24px" className="icons" /><p>Hjem</p></div></Link>
        <Link onClick={()=>{setOpen(true)}} to="/about"><div className="content"><FaInfoCircle color="#12192c" fontSize="24px" className="icons" /><p>Om</p></div></Link>
        <Link onClick={()=>{setOpen(true)}} to="/projects"><div className="content"><FaCogs color="#12192c" fontSize="24px" className="icons" /><p>Arkiv</p></div></Link>
        <Link onClick={()=>{setOpen(true)}} to="/contact"><div className="content"><FaMailBulk color="#12192c" fontSize="24px" className="icons" /><p>Kontakt</p></div></Link>
        

        {user? 
        ( <Link onClick={()=>{setOpen(true)}} to="/user-profile"><div className="content"><FaUserAlt color="#12192c" fontSize="24px" className="icons"/><p>{user}</p></div></Link>) 
        :
        ( <Link onClick={()=>{setOpen(true)}} to="/login"><div className="content"><FaUserAlt color="#12192c" fontSize="24px" className="icons"/><p>Log inn</p></div></Link>  )
        }
    </div>      
    </div>

    <div className="backdrop" onClick={handleSidebar}></div>

    </Sidebar>
    
    </>
  )
}


const Sidebar = styled.div`
position: fixed;
z-index:100;
transition: all 0.5s ease;
p{  
    padding-left:10px;
    padding-right:10px;
    padding-top: 2px;
    font-size:14px;
    font-weight:600;
}
.backdrop{
    z-index:90;
    transform: ${ props=>props.open ?   (`translateX(-100vw)`) : (`translateX(0)`)};
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:gray;
    opacity: 0.5;

}
.left{
z-index:100;
transform: ${ props=>props.open ?   (`translateX(-220px)`) : (`translateX(0px)`)};
position:fixed;
top:0;
left:0;
width:220px;
min-width:220px;
height:100vh;
background-color:#12192c;
transition: 0.2s;
padding:6px;
}

.top{
z-index:100;
transform: ${ props=>props.open ?   (`translateX(-100vw)`) : (`translateX(0px)`)};
position:fixed;
top:0;
left:0;
width:100vw;
min-width:200px;
height:60px;
background-color:#12192c;
transition: 0.2s;
padding:6px;

}

.right{
z-index:100;
transform: ${ props=>props.open ?   (`translateX(-100vw)`) : (`translateX(0px)`)};
position:fixed;
top:0;
right:0;
width:220px;
min-width:220px;
height:100vh;
background-color:#12192c;
transition: 0.2s;
padding:6px;
}

.arrowWrapper{
    float:left;
    width: 50px;
    cursor: pointer;
}

.xWrapper{
    float:right;
    width: 35px;
    height:35px;
    margin-right:10px;
    cursor: pointer;
}

.content:hover{
    transition: 0.2s;
    box-shadow: inset 0 0 0 100px #54b3d6;
    color: #ededed;

}
.content:hover p{
font-size: 16px;
    transition: 0.2s;

}
.content{
    margin:10px;
    padding-right:10px;
    padding-left:10px;
    background-color:#ededed;
    color:#12192c;
    float:left;
    width:190px;
    height:30px;
    border-radius:10px;
    display:flex;
    cursor: pointer;
    overflow:hidden;

}
.icons{
    padding-top:3px ;
}
`

