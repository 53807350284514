import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {getFirestore} from "@firebase/firestore";
import { getStorage} from "firebase/storage";
import { initializeApp } from "firebase/app";


//const app = firebase.initializeApp({
//        apiKey: process.env.REACT_APP_APIKEY,
//        authDomain: process.env.REACT_APP_AUTHDOMAIN,
//        projectId: process.env.REACT_APP_PROJECTID,
//        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//        appId: process.env.REACT_APP_APPID,
//        measurementId: process.env.REACT_APP_MEASUREMENTID
//})

const firebaseConfig = {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID
}

const app = initializeApp(firebaseConfig);

const auth = firebase.initializeApp(firebaseConfig).auth()

const db = getFirestore(app);

const storage = getStorage(app);


export {db, storage, auth}
//export default app